import mixpanel, { Dict } from 'mixpanel-browser';

import { SimplifiedLicense } from '../../hooks/useAccessBadge';
// Docs: https://developer.mixpanel.com/docs/javascript
import pkg from '../../package.json';
import { getBaseUrl, isDevelopment, isProduction } from '../../utils/client';

const getVersion = () => pkg.version || '-1';

let globalProperties: Dict = {
  version: getVersion(),
};

export default class Metrics {
  static instance: Metrics;

  distinctId?: string;

  constructor() {
    if (Metrics?.instance) {
      return Metrics.instance;
    }
    Metrics.instance = this;
    return this;
  }

  static getLogger() {
    if (!mixpanel) throw new Error('Not initialized properly');
    return {
      logEvent: (
        event: string,
        properties?: Dict,
        trackWhileUnauthenticated?: boolean,
      ) => {
        if (Metrics?.instance?.distinctId || trackWhileUnauthenticated) {
          return mixpanel.track(`APP.${event}`, { ...globalProperties, ...properties });
        }
      },
      logGameEvent: (
        event: string,
        properties?: Dict,
        isPreview?: boolean | null,
        isDemoGame?: boolean | null,
        trackWhileUnauthenticated?: boolean,
      ) => {
        if (Metrics?.instance?.distinctId || trackWhileUnauthenticated) {
          return mixpanel.track(
            `APP.${isDemoGame ? 'Demo.' : ''}${isPreview ? 'Preview.' : ''}${event}`,
            {
              ...globalProperties,
              ...properties,
            },
          );
        }
      },
    };
  }

  static withMetricsIdentifier(url: string) {
    if (typeof window !== 'undefined') {
      const postfix = url.endsWith('?')
        ? `SMTID=${this.getDistinctId()}`
        : `?SMTID=${this.getDistinctId()}`;
      return `${url}${postfix}`;
    } else {
      return url;
    }
  }

  static getDistinctId() {
    return mixpanel.get_distinct_id();
  }

  static init(token: string) {
    globalProperties = {
      version: getVersion(),
    };
    mixpanel.init(token, {
      api_host: `${getBaseUrl()}/api/stats`,
      ip: true,
      debug: !isProduction(),
      persistence: 'localStorage',
      ignore_dnt: isDevelopment(),
    });
  }

  static setFlag(flagName: string, isEnabled: boolean) {
    if (!new Metrics().distinctId) {
      //Prevent setting flags when user is not authenticated.
      //Unblock using useFlag on anonymous pages
      return;
    }
    mixpanel.register({
      [flagName]: isEnabled,
    });
  }

  static setProfileAndCompanyAttributes({
    email,
    name,
    plan,
    subPlan,
    curipodTrialReason,
    country,
    company,
    grades,
    hotjarRecordings,
    intendedUses,
    mbUserUsageLink,
    schoolRole,
    signUpOrigin,
    ssoProvider,
    subjects,
    schoolDistrictId,
  }: {
    email: string;
    name?: string;
    plan: string;
    subPlan: string;
    curipodTrialReason: SimplifiedLicense['source'];
    schoolRole?: string;
    grades?: string[];
    subjects?: string[];
    intendedUses?: string[];
    signUpOrigin?: string;
    mbUserUsageLink?: string;
    hotjarRecordings?: string;
    country?: string;
    ssoProvider?: string;
    schoolDistrictId?: number | null;
    company?: {
      plan: string;
      subPlan: string;
      curipodTrialReason: SimplifiedLicense['source'];
      createdAt: number;
      createdBy: string;
      schoolId: string;
      schoolName: string;
      districtId?: string;
      districtName?: string;
      city?: string;
      region?: string;
      state?: string;
      country?: string;
      zipCode?: string;
      mbOrgUsageLink?: string;
      mbDistrictUsageLink?: string;
    };
  }) {
    const companyAttributes = company
      ? {
          plan: company.plan,
          subPlan: company.subPlan,
          curipodTrialReason: company.curipodTrialReason,
          createdAt: company.createdAt,
          createdBy: company.createdBy,
          schoolId: company.schoolId,
          schoolName: company.schoolName,
          districtId: company.districtId,
          districtName: company.districtName,
          city: company.city,
          region: company.region,
          country: company.country,
          zipCode: company.zipCode,
          mbOrgUsageLink: company.mbOrgUsageLink,
          mbDistrictUsageLink: company.mbDistrictUsageLink,
        }
      : {};
    // Set attributes on the user profile
    mixpanel.people.set({
      $email: email,
      name,
      plan,
      subPlan,
      curipodTrialReason,
      country,
      ssoProvider,
      grades,
      hotjarRecordings,
      intendedUses,
      mbUserUsageLink,
      schoolRole,
      signUpOrigin,
      subjects,
      schoolDistrictId,
      ...companyAttributes,
    });

    // Register to put these attributes on every event emitted.
    mixpanel.register({
      plan,
      subPlan,
      curipodTrialReason,
      schoolRole,
      city: company?.city,
      region: company?.region,
      country: company?.country,
      zipCode: company?.zipCode,
    });

    this.getLogger().logEvent('appIdentifyRole');
  }

  static identifyUser(uid?: string) {
    if (uid) {
      new Metrics().distinctId = uid;
      globalProperties.uid = uid;
      // Put uid in every event.
      mixpanel.register({
        uid,
      });

      mixpanel.identify(uid);
      // Set uid for the user profile
      mixpanel.people.set({
        uid,
      });
    }
  }

  static incrementGamePlayed() {
    mixpanel.people.increment('Real.Games.Played');
  }
  static incrementTestGamePlayed() {
    mixpanel.people.increment('Test.Games.Played');
  }
  static incrementLessonsCreated() {
    mixpanel.people.increment('Lessons.Created');
  }
  static incrementLessonsDownloaded() {
    mixpanel.people.increment('Discover.Lessons.Downloaded');
  }
  static incrementGeneratorPlayed() {
    mixpanel.people.increment('Generator.Played');
  }
  static incrementDiscoveredLessonsPlayed() {
    mixpanel.people.increment('Discover.Lessons.Played');
  }

  static getUsageDashboardLink(
    type: 'individual' | 'tenant' | 'domain' | 'school' | 'district',
    value?: string,
  ) {
    if (!value) return undefined;
    const metricsReportURL =
      'https://eu.mixpanel.com/project/2182948/view/344387/app/boards#id=8230337';
    const urlParams = {
      individual: `filters=%7E%28%7E%28resourceType%7E%27user%7EpropertyName%7E%27uid%7EpropertyObjectKey%7Enull%7EpropertyDefaultType%7E%27string%7EpropertyType%7E%27string%7EfilterOperator%7E%27equals%7EfilterValue%7E%28%7E%27${value}%29%29%29`,
      tenant: `filters=%7E%28%7E%28resourceType%7E%27user%7EpropertyName%7E%27companyId%7EpropertyObjectKey%7Enull%7EpropertyDefaultType%7E%27string%7EpropertyType%7E%27string%7EfilterOperator%7E%27equals%7EfilterValue%7E%28%7E%27${value}%29%29%29`,
      domain: `filters=%7E%28%7E%28resourceType%7E%27user%7EpropertyName%7E%27%21email%7EpropertyObjectKey%7Enull%7EpropertyDefaultType%7E%27string%7EpropertyType%7E%27string%7EfilterOperator%7E%27contains%7EfilterValue%7E%27${value}%29%29`,
      school: `filters=%7E%28%7E%28resourceType%7E%27user%7EpropertyName%7E%27schoolId%7EpropertyObjectKey%7Enull%7EpropertyDefaultType%7E%27number%7EpropertyType%7E%27number%7EfilterOperator%7E%27is*20equal*20to%7EfilterValue%7E${value}%29%29`,
      district: `filters=%7E%28%7E%28resourceType%7E%27user%7EpropertyName%7E%27schoolDistrictId%7EpropertyObjectKey%7Enull%7EpropertyDefaultType%7E%27number%7EpropertyType%7E%27number%7EfilterOperator%7E%27is*20equal*20to%7EfilterValue%7E${value}%29%29`,
    };
    return `${metricsReportURL}&${urlParams[type]}`;
  }

  static getMetabaseOrgUsageLink(orgId?: string) {
    if (!orgId) return undefined;
    return `https://metabase.curipod.org/dashboard/34-usage-v2?school_organization_id=${orgId}&tab=7-school-usage-by-org-id`;
  }
  static getMetabaseDistrictOrgUsageLink(orgId?: string) {
    if (!orgId) return undefined;
    return `https://metabase.curipod.org/dashboard/34-usage-v2?district_org_id=${orgId}&tab=6-usage-by-organization-id`;
  }
  static getMetabaseUserUsageLink(email?: string) {
    if (!email) return undefined;
    return `https://metabase.curipod.org/dashboard/34-attio-usage-deep-dive?email=${encodeURIComponent(
      email,
    )}&tab=8-email-usage`;
  }
}
