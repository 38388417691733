export const config = {
  cloudMessaging: {
    vapidKey:
      'BNg04rRat94mcemMQgPwQe35EaJvOf9LkkaKqNoUa3Yyx4c6WEKShKb6JudmK38VLpmubwKfe781BRYSRnezhrg',
  },
  backend: {
    externalApiUrl: 'https://api.curipod.com',
  },
  cdn: {
    url: 'https://images-cdn.curipod.com',
  },
  sentry: {
    dsn: 'https://a46739c4f3e147b6a07b3f8636ee043a@sentry.io/1757705',
  },
  stripe: {
    publicKey: 'pk_live_KqY7Og9T3kZdWfnNzHf2FYEK00b4VMogqD',
  },
  appVersion: process.env.REACT_APP_VERSION,
  mixpanel: {
    token: 'b46a26a9b370ce2c5bdf57ee1092b62b',
  },
  azure: {
    tenant: 'snapmentorprod.onmicrosoft.com',
    tenantName: 'snapmentorprod',
    clientId: '47e477d5-a501-44cd-8599-817b011b2a81',
    signInPolicy: 'B2C_1A_signin',
    joinPolicy: 'B2C_1A_join',
    signUpPolicy: 'B2C_1A_signup',
    enterpriseSignInPolicy: 'B2C_1A_enterprise_signin',
    enterpriseJoinPolicy: 'B2C_1A_enterprise_join',
    scopes: [
      'https://snapmentorprod.onmicrosoft.com/47e477d5-a501-44cd-8599-817b011b2a81/User.Read',
    ],
    enterpriseRedirectUri: 'https://id.curipod.com',
    prRedirectUri: '',
    redirectUri: 'https://app.curipod.com/oauth/callback',
  },
  onboarding: {
    version: 2,
  },
  whatsNew: {
    version: 8,
  },
  game: {
    url: 'curi.live',
    apiUrl: 'https://api-live.curipod.com',
  },
  legacyApp: {
    url: 'https://app.curipod.com',
  },
  externalLinks: {
    howItWorks: 'https://curipod.com/how-it-works',
  },
  unleash: {
    url: 'https://unleash-proxy-7epohblsoa-ey.a.run.app/proxy',
    clientKey: '7epohblsoa',
    appName: 'app-curipod',
    refreshInterval: 60 * 5,
  },
  discovery: {
    generatorIds: [
      '753d7c32-4f9b-42bf-bb53-12419c28425d', // full lesson
      '2381937b-df71-4c2f-92a4-9bce03f5541e', // Vocabulary meme battle
      '4782004a-3d23-4a93-8c6d-61d6609b9e69', // full lesson - bilingual
      '4976a2b9-cc8a-4900-b05e-2f9f7ac02ef9', // Curify my slides
      '6c3b4137-0dbd-40ec-b228-5515a34fb52f', // Curi-Quiz
      '526687e6-db4a-4174-9f14-61297d8bec53', // Vocabulary Hustle by Curipod creator studio
      '713cacb6-e856-4d8d-a314-564b994d7f6a', // Writing superpower
      'de157dd4-6eb9-48af-b9de-dad096675ea9', // Feedback from historical figure
      'f6376bd4-94f3-41b3-a3bd-d3bf9a47b708', // Co-write with AI
      '6ff39a67-250f-49bb-86be-36c9041991c7', // Feedback in spanish
      '1df6375c-9d71-4a8d-a0c9-254d8a21adf8', // Convince the Evil AI
      '3d74e28b-f2d3-481f-b836-ba4b89faa9d2', // pdf to comprehension questions
      '35a78cb0-5674-4a76-9c5b-d0a27e8b49f2', // Do you infer the country?
      'c81156a0-79bf-449b-9c28-c30012c7adc1', // paragraph of the week
      '31c13fa7-5f08-4a8c-97cf-0ac1e79a2cc5', // brain break
      '5b20be54-64ad-42fc-a964-a062b5604e0b', // would you rather
    ],
    fullLessonIds: [
      '753d7c32-4f9b-42bf-bb53-12419c28425d', // full lesson
    ],
    fullLessonIdsSocialStudies: [
      'ebe9ae37-ac72-488d-ae37-023d5e3e46e1', // full lesson social studies
    ],
    fullLessonIdsMath: [
      'b3805995-8c62-4be4-ad44-ba5a0ccd940c', // full lesson math
    ],
    fullLessonIdsScience: [
      '9edbf2d9-472e-492c-ac29-f0201f13aed7', // full lesson science
    ],
    didNotFindTryTheseSuggestionIds: [
      '753d7c32-4f9b-42bf-bb53-12419c28425d', // full lesson
      '4976a2b9-cc8a-4900-b05e-2f9f7ac02ef9', // Curify my slides
      '31c13fa7-5f08-4a8c-97cf-0ac1e79a2cc5', // brain break
    ],
    recommended: [
      '122308b0-1a9c-4dc4-b462-488a8b8d5bfc', // Give Back on Giving Tuesday - Write Now! 3/12/24
      '4244c4ec-068d-489a-a597-3ee9aab6bb2c', // What Makes a Hero? - Write Now! 19/12/24
      '0d90b4f9-30a2-4f35-9545-e5e9e63eb463', // Do You Want to Build a Snowman? - Write Now!
      '83079155-8ec2-4afb-b7f0-af9cf17c064a', // Snowman's Greatest Adventure - Write Now!
      '562ee4d4-40b0-4055-aa73-16f97f09e7c8', // Debate of the Seasons - Write Now!
      '526687e6-db4a-4174-9f14-61297d8bec53', // Vocabulary Hustle
      '2381937b-df71-4c2f-92a4-9bce03f5541e', // Vocabulary Meme Battle
      '5b20be54-64ad-42fc-a964-a062b5604e0b', // Would you rather
      '31c13fa7-5f08-4a8c-97cf-0ac1e79a2cc5', // brain break
      'de157dd4-6eb9-48af-b9de-dad096675ea9', // Feedback from a historic figure
      '4136f4b3-9205-4014-9742-6a30986312fe', // Infer the fictional character
      '35a78cb0-5674-4a76-9c5b-d0a27e8b49f2', // Infer the country
    ],
  },
  testprep: {
    //*STAAR RLA SCR
    staarRLASCRArgumentative: '11f7bab6-897c-42d1-a3ee-9c307aa971ed',
    staarRLASCRInformational: '70f62c07-9f14-48f5-be3a-b065b6136c6f',
    staarRLASCRFiction: '778228d1-afc1-4d83-97e8-2d3a97dfd8a2',
    staarRLASCRRevisingSentences: '36c2b7ec-602b-4075-a5e9-b482fe11ac85',
    staarRLASCRUpload: '4b6487cd-9c83-41ae-8d8d-e9f5b1378f4c',
    //*STAAR RLA ECR
    staarRLAECRInformationalComposition: 'c30ecdf9-da94-4970-9de5-77c45fde714d',
    staarRLAECRArgumentativeComposition: 'a9a8f823-68cb-4f26-8a9f-aabd6d55f1a0',
    staarRLAECRInformationalCorrespondence: '2dabf4c6-08d7-4109-bca5-881c71e789d8',
    staarRLAECRArgumentativeCorrespondence: '2f8ecb57-33e0-476c-acf4-b3267c6f0567',
    staarRLAECRUploadInformational: 'd9142546-2e68-4d4d-921c-dd873ab30d9a',
    staarRLAECRUploadArgumentative: '31ab970c-8137-40e0-a091-651ac215b3a4',
    //*STAAR Social Studies
    staarSocialStudiesSCR: '7e283104-ade3-498d-89d5-b9e52aaf3cad',
    staarSocialStudiesSCRUpload: '7a0238e0-9f99-4a5f-b57c-65f05aeefacd',
    //*STAAR US History
    staarUSHistorySCR: 'd622c4fb-9244-407a-8457-8fe1abcc71ce',
    staarUSHistorySCRUpload: 'a505b086-d587-4ed3-826c-bcee3c180107',
    //*STAAR Science
    staarScienceSCR: 'ca6cd604-e032-4390-a1d9-92c95ba91e34',
    staarScienceSCRUpload: 'eb0e5bb7-2174-4585-9583-19f1cc64f388',
    //*STAAR Biology
    staarBiologySCR: '5886ef3b-589a-43e4-9a5e-e69651832be6',
    staarBiologySCRUpload: 'c3ed74e4-61fa-4c5d-9b4b-ff95c5fa1802',
    //*STAAR Math
    staarMathTextInput: '2570d166-d23d-4ead-95a9-c957d5943147',
    staarMathTextInputUpload: '3b98c525-d34c-4dc7-952a-672f9fa59415',
    //*STAAR Algebra I
    staarAlgebraITextInput: '530ec541-14ef-4b74-9470-7312739dbb1b',
    staarAlgebraITextInputUpload: '18935283-b2a6-46fe-8dd7-5630be733f33',
    //*TELPAS
    telpasTellAStoryAnswerQuestions: 'fac991b4-e9a4-4040-94fd-0fb1072f2005',
    telpasTELLAStoryFourPictures: '72d7a5d5-e1bf-469b-8e92-74a6914b57da',
    telpasTELLAStoryOnePicture: '6b182251-b1a2-475a-9699-b771d9c889ee',
    telpasDescribeThePictures: '22ccc49b-8cf4-4194-bbc1-dea2e25df286',
    telpasRespondToAScenario: 'c036aab1-3697-46bf-ba06-0411a0496580',
    telpasWriteAboutBarGraph: 'b1bd351b-8804-48e4-a9b9-a10eef095b1e',
    telpasWriteAboutPictureChart: '833ce346-6d27-40b6-a4a5-22e56a7ac768',
    //*CAASPP ELA Short Answer
    caasppELAShortAnswerInformational: '0c987f31-3bf5-465d-886a-b58cdd458069',
    caasppELAShortAnswerArgumentative: '67f893dc-27a6-4f9b-beeb-7448849c0932',
    caasppELAShortAnswerFiction: 'e7695c10-4352-4093-84f9-8c2ca986b645',
    caasppELAShortAnswerUpload: 'e6b81803-c0dc-4bd7-b3e7-298c848a6054',
    //*CAASPP ELA Performance Task
    caasppELAPerformanceTaskInformationalComposition:
      '0f37856e-36c9-458e-a478-10e3b3641067',
    caasppELAPerformanceTaskArgumentativeComposition:
      '4eb08097-7c91-4c82-8fda-0f58b76afb05',
    caasppELAPerformanceTaskNarrativeComposition: '50a0c6b0-149b-4757-9e05-e02a9b89378f',
    caasppELAPerformanceTaskUploadInformational: '90133d30-68d9-4d1a-9441-8c0a846f5487',
    caasppELAPerformanceTaskUploadArgumentative: '9329a049-5ed0-43c1-b576-df52a60fa3fc',
    caasppELAPerformanceTaskUploadNarrative: 'b4569dac-0747-4454-9c2d-f59112928c0b',
    //*CAASPP Math
    caasppMathTextInput: '8bb36bda-f4af-4bf0-a227-f3bf9f3bcee2',
    caasppMathTextInputUpload: 'cf321345-5925-4971-8d72-abcce2dd0be4',
    //*CAASPP Science
    caasppScienceSCR: '24029959-6df4-41e4-b8e9-064217305158',
    caasppScienceSCRUpload: '9f8366a9-8f4e-473c-aa57-efb933340cb9',
    //*PSSA ELA Short Answer
    pssaSAInformational: '90aef579-e770-466a-aa07-181d2e387c93',
    pssaSAUpload: '88bcf116-7374-4b5d-9ce2-c0a4a667ac9c',
    //*PSSA ELA Text Dependent Analysis
    pssaTDAInformational: 'b483ab19-4535-459a-bf32-08a7a139a058',
    pssaTDANarrative: 'a731c33f-f8f5-44c6-821d-9ccc7c879ddd',
    pssaTDAUpload: '553b09ae-e978-41f9-bd29-9372a7d3ecc7',
    //*PSSA Science
    pssaScienceShortAnswer: '5385ec1f-7630-4e13-86bb-3d5d4c81fea2',
    //*MICHIGAN M-STEP ELA
    mstepPBWInformationalComposition: '68a94769-290f-49f2-81d9-9f2ad8a92b50',
    mstepPBWUpload: 'ca7cc0dc-80f0-43dc-bff3-1954967958ed',
    //*NC STATE TEST ELA
    ncStateTestCRInformationalComposition: '6af21ed8-940a-469c-a630-825cdc38369a',
    ncStateTestELAUpload: 'a76e36b9-bc74-47be-a175-bcd74907cc7f',
    //*SBAC
    //SBAC ELA Short Answer
    sbacELAShortAnswerInformational: 'a49ba927-9781-4f46-a84a-cd9ca73e50a2',
    sbacELAShortAnswerArgumentative: '8f52fc44-3203-4ed9-967b-9bb3bcdb9c72',
    sbacELAShortAnswerFiction: 'fa469124-1920-4cbb-b7ac-af4d3154ace9',
    sbacELAShortAnswerUpload: '8c5877a9-cb42-45be-89a9-7804f6c12d7f',
    //SBAC ELA Performance Task
    sbacELAPerformanceTaskInformationalComposition:
      'e65b3144-e58a-4a58-a1e6-4a7f88f41999',
    sbacELAPerformanceTaskArgumentativeComposition:
      '123fb13f-570b-41d8-8aac-10eb5cd20623',
    sbacELAPerformanceTaskNarrativeComposition: 'ab61a8ac-8f94-4439-a3e6-05da36fae7b2',
    sbacELAPerformanceTaskUploadInformational: '5707090b-2ad2-4098-a5fe-c74f6335928c',
    sbacELAPerformanceTaskUploadArgumentative: '3147a9f1-1081-43be-a5bf-7ebeba5ec80b',
    sbacELAPerformanceTaskUploadNarrative: 'de4389de-494f-4c14-8524-cb0cc0e48ff8',
    //*ELPAC
    elpacWriteAboutAnExperience: '4c533a51-cb74-4999-ab1a-5c0280a3f452',
    //*NY REGENTS
    regentsTextAnalysisInformational: 'a61c1971-0b11-4675-bdaf-838b381c8cda',
    //*WIDA ACESS
    widaTierAWriteStory: '47863c5f-10a9-442f-b589-7c5f454338a6',
    widaTierAMakeADecision: 'c0dbc253-8701-47c3-be7a-e0b7ad01374c',
    widaTierBCStepByStepDescription: 'f425bc3b-9ded-40bf-9956-2f4848a11fc9',
    //*RACE
    raceConstructedResponse: 'f5e973a7-0a30-434d-a1df-325eb3d21f4e',
    raceUpload: '5cdf09f2-65c2-4464-8da6-c7cd5b062748',
  },
};
