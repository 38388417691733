import { Standard } from '../../components/_organisms/DesignerTabPane/GeneratorsTab/utils';
import { Slide } from '../../components/SlideComponents/slide.types';
import { AiAssessedScore } from '../../frontendServices/gameAPI';
import {
  AiConsumerContent,
  AiContent,
  GeneratorCoarseLicenseLevel,
} from '../TemplateService/Template.type';
import { LessonAttachment } from './backendService';

export type UserTenant = {
  activeTenantId: string;
  availableTenantIds: string[];
  availableTenants: { tenantId: string; tenantName: string }[];
};

export type ById<T> = {
  [id: string]: T;
};

export type Round = {
  id: string;
  type: RoundType;
  title?: string | null;
  durationSeconds?: number | null; // transactional
  durationSecondsConfig?: number | null; // configurable
  wasPlayed?: boolean | null; // transactional
  dataReferenceRoundId?: string | null;
  media?: RoundMedia | null;
  content?: SlideContent | null;
  metadata?: ById<string | boolean | number> | null;
  roundOptions?: RoundOptions[] | null;
  responseLimit?: number | null;
  responseCharacterLimit?: number | null;
  wasAddedDuringLive?: boolean | null;
  backgroundMedia?: RoundMedia | null; // Place a media as background
};

export type PreviewTemplate = {
  id: string;
  slides: Slide[];
  creatorId: string;
  creatorProfileId: string;
  coverImageId?: string | null;
  coverImageAltText?: string | null;
  creatorThumbnailId?: string | null;
  title?: string | null;
  creatorName: string;
  modifiedAt: number;
  metadata?: ById<string | number | boolean> | null;
  description?: string | null;
  tenantId: string;
  isPrivate: boolean;
  requireRealNames?: boolean;
  language: string | null;
  downloadCount: number;
  playCount: number;
  aiContent?: AiContent;
  attachments?: LessonAttachment[];
  activeGame?: {
    id: string;
    isOngoing: boolean;
  } | null;
};

export type RoundType =
  | 'questions' /** @deprecated */
  | 'voting'
  | 'answers'
  | 'aiAssessedAnswers'
  | 'aiAssessedImageAnswers'
  | 'ideas'
  | 'slide'
  | 'canvas'
  | 'responsegrid'
  | 'wordcloud'
  | 'poll'
  | 'videos' /** @deprecated */
  | 'groupmaker' /** @deprecated */
  | 'podium';

export type SlideType =
  | 'header'
  | 'header_text'
  | 'bullets'
  | 'image'
  | 'text_image'
  | 'image_text'
  | 'fullscreen_media';
export type SlideBullet = {
  id: string;
  text: string;
};
export type RoundOptions = {
  id: string;
  displayName: string;
  count?: number;
  color?: string;
};

export type CustomSlideTextSize = 'XS' | 'S' | 'M' | 'L' | 'XL' | '2XL' | '3XL';

export type SlideContent = {
  type: SlideType;
  title: string | null /** @depricated due to custom slides using round.title*/;
  subtitle: string | null;
  bullets?: SlideBullet[] | null /** @depricated due to custom slides */;
};

export type RoundMedia = {
  fileId: string;
  thumbnailSrc?: string | null;
  fileMimeType: string;
  fileAltText?: string | null;
  downloadLocation?: string | null;
  cropConfig?: CropConfig | null;
  backgroundDrawingFileId?: string | null;
  brightness?: number | null;
  backgroundDrawingConfigFiles?: RoundMediaDrawingFile[] | null;
  width?: number;
  height?: number;
};

export type RoundMediaDrawingFile = {
  id: string;
  created: number;
  mimeType: string;
};

export type CropConfig = {
  height: number;
  width: number;
  naturalHeight: number;
  naturalWidth: number;
  x: number;
  y: number;
};

export type LookupTenant = { tenantId: string; tenantName: string };

export type Invitation = {
  code: string; // The code used to verify. Will also be its Id
  tenantId: string; // The tenantId this code belongs to
  expiresAt: number | null; // If null means it will never expire
  createdBy: string;
  role: UserRole;
  email: string | null;
  topic?: TopicInvitation;
};

export type InvitationRole = 'mentor' | 'student';

export type TopicRole = 'follower' | 'moderator' | 'owner';

export type TopicInvitation = {
  topicId: string;
  type: TopicRole;
};

export type EmailInvitationCreateDTO = {
  email: string;
  role: UserRole;
  topic?: TopicInvitation;
};

export type RoundResult = {
  questions?: Response[];
  responses?: Response[];
  id: string;
  votes?: Vote[];
};

export type Vote = {
  id: string;
  selectedQuestionId: string;
  selectedResponseId?: string;
  questionAId: string;
  questionBId: string;
  responseAId?: string;
  responseBId?: string;
  votedBy: string;
};

export type Participant = {
  id: string;
  gameId: string;
  tenantId: string;
  nickName: string;
  token: string; // the token passed with all api requests from the anonymous user. Generated in the monolith
  authToken: string;
  name?: string;
  isKicked?: boolean;
  joinedAt?: number;
};

export type PartialParticipant = Pick<
  Participant,
  'id' | 'nickName' | 'name' | 'isKicked'
>;

export type GamePinRegistration = {
  pin: string;
  tenantId: string;
  createdAt: number;
  gameId: string;
};

export type Game = {
  id: string;
  topicId?: string | null;
  pin: string | null;
  title?: string | null;
  isPlaying: boolean;
  isComplete: boolean;
  isPreviewGame: boolean;
  isFromDiscover?: boolean | null;
  isDemoGame?: boolean | null;
  templateId?: string | null;
  areAllRoundsCompleted?: boolean | null;
  metadata?: ById<string | boolean | number>;
  roundStartsAt: number | null;
  roundFreezedAt: number | null;
  currentRound: string | null;
  lastPlayedAt: number | null;
  isShowingLobby?: boolean | null;
  createdAt: number | null;
  modifiedAt: number | null;
  language?: string | null;
  activity?: GameActivity;
  host: {
    uid: string;
    username?: string;
  };
  rounds: Round[] | null;
  slides: Slide[] | null;
  attachments?: LessonAttachment[];
  roundResults?: RoundResult[]; // included when complete
  participants?: Participant[]; // included when complete
  requireRealNames?: boolean;
};
export type GameSaveDTO = {
  id: string;
  tenantId?: string | null;
  title: string | null;
  pin: string | null;
  isPreviewGame: boolean;
  isFromDiscover: boolean | null;
  templateId: string | null;
  language: string | null;
  isPlaying: boolean;
  isComplete: boolean;
  areAllRoundsCompleted: boolean;
  requireRealNames: boolean;
  roundStartsAt: number | null;
  roundFreezedAt: number | null;
  currentRound: string | null;
  isShowingLobby: boolean | null;
  lastPlayedAt: number | null;
  createdAt: number;
  metadata: ById<string | number | boolean> | null;
  modifiedAt: number;
  host: {
    uid: string;
  };
  rounds: Round[];
  slides: Slide[];
  attachments?: LessonAttachment[];
};

export type PaginatedGameResults = {
  items: Game[];
  cursor?: number;
  count: number;
};

export type Score = {
  score: number;
  questionId: string;
  question: Response;
  responseId: string;
  response: Response;
  feedback?: AiAssessedScore;
};

export type Response = {
  id: string;
  question: string;
  response: string;
  uid: string;
  timestamp?: number;
  modifiedAt?: number;
  isFinished?: boolean;
  dismissed?: boolean;
};

export type LeaderBoard = Score[];

export type GameActivity = 'kickstart' | 'summarize' | 'brainstorm' | 'answer';

export type NotificationType =
  | 'chatRoomAvailableForClaim'
  | 'openUrl'
  | 'relevantContent'
  | 'reminder'
  | 'achievement'
  | 'newMessage'; // should say something about how the frontend should handle the type of notification and also how they relate to settings

export type GenericNotification = {
  id: string;
  receiver: string; // Will be used to lookup the token(s) for the devices and the email
  received: boolean; // acknowledges if the notification was received. Mark as read
  receivedTime: number | null;
  sentTime: number | null;
  payload: {
    // This is data actually transmitted in the notification payload
    type: NotificationType;
    title: string;
    body: string;
    disableInApp?: string; // boolean
    redirect?: string;
    redirectSelfServe?: string;
  };
};

export type ArticleDiscussionPost = {
  discussionId: string;
  includedMessages: { id: string; changedText?: string }[];
};

export type UserRole = 'admin' | 'mentor' | 'student';

export type ArticleStatus =
  | 'parsing'
  | 'draft'
  | 'review'
  | 'published'
  | 'rejected'
  | 'archived';

const logos = [
  ':curipod_stars:',
  ':curipod_thanks:',
  ':curipod_hearts:',
  ':curipod_normal:',
] as const;

export type LogoType = typeof logos[number];

export type ProfileLogoConfig = {
  color: string;
  logo: LogoType;
};

export type QuestionData = {
  images: {
    id: string;
    timestamp: number;
  }[];
};

export type ArticleComment = {
  createdAt: number;
  updatedAt: number;
  text: string;
  createdBy: string;
  id: string;
  articleId: string;
  isAnonymous?: boolean | null;
  imageId?: string | null;
  disclaimer?: string | null;
  reactions?: ReactionDTO[] | null;
  colorConfig?: ProfileLogoConfig;
  createdByNameFallback?: string;
};

export type ReactionDTO = {
  id: string;
  type:
    | ':snapmentor_like:'
    | ':snapmentor_love:'
    | ':snapmentor_stars:'
    | ':snapmentor_brain_explosion:'
    | ':snapmentor_appreciate:'
    | string; // unicode or custom emoji
  createdBy: string;
  timestamp: number;
  count: number;
  entityType: ReactionEntityType;
  entityId: string;
  parentId?: string | null;
};

export type ReactionEntityType = 'article' | 'comment' | 'message';

export type ArticleType =
  | 'pdf'
  | 'image'
  | 'discussion'
  | 'onboarding'
  | 'question'
  | 'post';

// Tags should be the name of the tag, not the id :)
export type SearchFilter = {
  tags?: string[];
};

export type ArticleDocument = {
  id: string;
  title: string;
  labels: string[];
  text: string;
  createdAt: number;
  updatedAt: number;
  authorName: string;
  authorId: string;
  authorEmail: string;
  path: string;
  totalClaps: number;
  type: ArticleType;
  isAnonymous?: boolean | null;
};

export type ArticlePut = {
  id: string;
  text?: string;
  title?: string;
  labels?: string[];
  status?: ArticleStatus;
};

export type OAuthProvider = 'vipps' | 'feide' | 'google';

export type NotificationPreferences = {
  news: DeliveryMethod;
  snippets: DeliveryMethod;
};

export type DeliveryMethod = {
  email: {
    enabled: boolean;
  };
  push: {
    enabled: boolean;
  };
};
export type Claims = {
  admin?: boolean;
  mentor?: boolean;
  tenantId?: string;
  tenantAdmin?: boolean;
  provider?: string;
};
export type APIClaims = {
  mentor: boolean;
  tenantAdmin: boolean;
};

export interface VippsToken {
  access_token: string;
  expires_in: number;
  id_token: string;
  scope: 'openid';
  token_type: 'bearer';
}

export type AvailableSubscriptionDTO = {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  product: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prices: any[];
  unit?: { type: 'minutes'; amount: number };
};

export type MSClaims = {
  admin: boolean;
  mentor: boolean;
  student: boolean;
};
export type RoleUser = {
  id: string;
  name: string;
  createdAt: string;
  email?: string;
  roles: MSClaims;
};

export type AvailableFilterDTO = {
  key: string;
  doc_count: number;
};

export type AvailableFilterResponse = {
  labels: AvailableFilterDTO[];
  authors: AvailableFilterDTO[];
  articleTypes: AvailableFilterDTO[];
};

export type TopicDTO = {
  id: string;
  displayName: string;
  description?: string | null;
  disabled: boolean;
  isPrivate: boolean;
  deleted: boolean;
  createdBy: string;
  owners: string[];
  membersCount?: number;
};

export type TopicCreateDTO = Omit<TopicDTO, 'id'>;

export type TopicSaveDTO = TopicCreateDTO | TopicDTO;

export type TopicSaveResponseDTO =
  | TopicDTO
  | {
      id?: string;
      name: string;
      action: 'save' | 'create';
      success: boolean;
    };

export type OnboardingStatus = {
  hasCreatedDiscussion: boolean;
  hasSetNickName: boolean;
  hasEndedChat: boolean;
  hasCreatedSnippet: boolean;
  isFollowingSomething: boolean;
};

export type DidYouKnow = {
  [didYouKnowKey: string]: number;
};

export type MyFollowing = {
  topic?: TopicDTO;
  uid: string;
  timestamp: number;
  entityId: string;
  type: 'topic' | 'article';
};

export type Tag = {
  id: string;
  name: string;
  topicId: string;
  color?: string;
};

export type GameEditOrCreateDTO = {
  rounds?: Round[];
  slides?: Slide[];
  attachments?: LessonAttachment[];
  topicId?: string | null;
  title?: string | null;
  gameId?: string | null;
  currentRound?: string | null;
  modifiedAt?: number | null;
  roundStartsAt?: number | null;
  isPlaying?: boolean; // @deprecated, this shoudl be removed when we're at flexible lobby
  lastPlayedAt?: number | null;
  gameIds?: string[];
  requireRealNames?: boolean;
  isShowingLobby?: boolean;
};

export type ActivityResults = {
  totalParticipants: number;
  activeParticipants: number;
  participants: ById<Participant>;
  slidesResults: ActivitySlideResult[];
};

export type ActivitySlideResult = {
  slides: Slide[];
  scores: Score[];
  wasPlayed: boolean;
  activeParticipants: number;
};
export type ActivityRoundResult = {
  rounds: Round[];
  scores: Score[];
  wasPlayed: boolean;
  activeParticipants: number;
};

export enum HostActivationEventNameEnum {
  play_demo = 'play_demo',
  create_game = 'create_game',
  host_game = 'host_game',
  host_dismiss = 'host_dismiss',
}

export const HostActivationEventNameEnums = Object.values(HostActivationEventNameEnum);

export const ValidHostActivationEventNameEnums = HostActivationEventNameEnums.filter(
  val => val !== HostActivationEventNameEnum.host_dismiss,
);

export type HostActivationEventName = keyof typeof HostActivationEventNameEnum;

export type HostActivationEvent = {
  createdAt: number;
  name: HostActivationEventName;
  uid: string;
};

export type HostActivationResponse = {
  isDismissed: boolean; // Easy to use for the frontend to avoid rendering the progression bar.
  events: HostActivationEvent[]; // Easy to use for frontend to match progress components to data.
};

export type DiscoveredTemplateStatus =
  | 'published'
  | 'rejected'
  | 'submitted'
  | 'unPublishedByOwner'
  | 'staging';

export type DiscoveredTemplate = {
  id: string;
  tenantId: string;
  title: string;
  description: string | null;
  lastPublished: number | null;
  firstPublished: number | null;
  createdAt: number;
  modifiedAt: number;
  creatorId: string;
  creatorProfileId: string;
  creatorThumbnailId?: string | null;
  rounds: Round[]; // @deprecated due to slides
  slides: Slide[];
  language: string | null;
  coverImageId: string | null;
  coverImageAltText: string | null;
  downloadCount: number;
  playCount: number;
  participantCount?: number;
  tags: string[];
  grades: string[];
  creatorName: string;
  status: DiscoveredTemplateStatus;
  requireRealNames?: boolean;
  adminTags?: string[];
  adminLanguage?: string;
  roundIndexField?: string;
  aiConsumerContent: AiConsumerContent;
  slideIndexField?: string;
  aiContent?: AiContent;
  learningStandards?: Standards[];
};

export type IndexedTemplate = Omit<DiscoveredTemplate, 'status'>;

export type DiscoverSearchQuery = {
  filters?: {
    languages?: string[] | null;
    subjects?: string[] | null;
    grades?: string[] | null;
    hotFilter?: string[] | null;
  } | null;
  searchPhrase?: string | null;
  cursor?: number | null;
  limit: number;
  sort?: ById<ById<string>>[];
};

export type DiscoverSearchResults = {
  items: IndexedTemplate[];
  total: number;
  cursor?: number | null;
};

export type TemplateDataType = {
  id: string;
  tenantId: string;
  type: 'template';
  parentId: string | null;
  title?: string | null;
  description?: string | null;
  lastPublished?: number;
  isPrivate: boolean;
  coverImageId?: string | null;
  coverImageAltText?: string | null;
  clonedFromTemplateId?: string | null;
  templateSharingPublic?: boolean | null;
  isBlockedFromSharing?: boolean | null;
  createdAt: number;
  requireRealNames?: boolean;
  modifiedAt: number;
  topicId?: string | null;
  isDeleted?: boolean | null;
  currentRound?: string | null;
  deletedAt?: number | null;
  language?: string | null; // en, nb-NO, de
  creator: {
    uid: string;
  };
  members: string[];
  content: TemplateContent;
  // This is data storage only - will be hidden from clients
  workspaceActiveGame?: WorkspaceActiveGame;
  metadata?: ById<string | boolean | number> | null;
};

export type TemplateContent = {
  rounds: Round[] | null;
  gameIds: string[];
  activeGame?: ActiveGame;
};

export type WorkspaceActiveGame = ById<ActiveGame>;

export type ActiveGame = {
  id: string;
  isOngoing: boolean;
} | null;

export type FeedbackType = 'writingFeedback' | 'comprehensionFeedback';

export type PromptMessageRole = 'user' | 'system' | 'assistant';

export type PromptDependency = {
  dependencyId: string;
  type: 'formInput' | 'promptOutput' | 'globalPromptMiddleware';
};

export type LocalPromptDependency = {
  id: string;
  type: 'input' | 'output' | 'live';
  label: string;
  formType: PromptInputType;
  defaultValue?: string;
  constraints?: PromptInputConstraints;
};

export type Prompt = {
  id: string;

  name: string;
  // The specific string like `create a funny drawing activity based on {event}`
  messages: { role: PromptMessageRole; content: string | undefined }[];

  promptOutput: PromptOutput;
  temperature?: number;

  negativePrompt?: string;
  // prompt execution
};

export const AIResourceTypes = {
  openai: 'openAi',
  openaiImage: 'openAi-image',
  openAiMultiModal: 'openAi-multiModal',
  stableDiffusionImage: 'stable-diffusion-image',
  // midjourney: 'midjourney',
} as const;

export type ResourceTypeKeys = keyof typeof AIResourceTypes;
export type ResourceType = typeof AIResourceTypes[ResourceTypeKeys];

export const promptModels = {
  default: 'default',
  // Legacy models (Stable Diffusion)
  sdCore: 'sd-core',
  sdUltra: 'sd-ultra',
  sd3Turbo: 'sd3-large-turbo',
  sd3: 'sd3-large',
  // Supported models (Stable Diffusion)
  sd3Medium: 'sd3-medium',
  sd35Medium: 'sd3.5-medium',
  sd35Large: 'sd3.5-large',
  sd35LargeTurbo: 'sd3.5-large-turbo',
  // Supported models (OpenAI)
  gpt35Turbo: 'gpt-3.5-turbo',
  gpt4: 'gpt-4',
  gpt4o: 'gpt-4o',
  gpt4omini: 'gpt-4o-mini',
  gpt4Turbo: 'gpt-4-turbo',
  dalle3: 'dall-e-3',
  gpt4Vision: 'gpt-4-vision-preview',
} as const;

export type PromptModelKeys = keyof typeof promptModels;
export type PromptModels = typeof promptModels[PromptModelKeys];

export type PromptResource = {
  type: ResourceType;
  model: PromptModels;
  imageOrientation?: 'landscape' | 'portrait' | 'square';
};

export type PromptItemProperty = {
  type: 'string' | 'number';
  id: string; // `${promptId}.${property.id}.${itemIndex}.${itemValue.id}`
  description?: string;
};

export type PromptOutputPropertyItemType = 'string' | 'number' | 'object';

export type PromptOutputProperty = {
  type: 'string' | 'number' | 'array';
  id: string;
  description?: string; // used by openai to match the prompt arguments
  // Used by array
  items?: {
    // used if array
    type: PromptOutputPropertyItemType;
    maxLength: number;
    properties?: {
      // used if object
      [name: string]: PromptItemProperty;
    };
    propertiesOrder?: string[];
  };
  // not used
  // enum?: string[];
};

export type PromptOutput = {
  type: 'string' | 'object' | 'array';
  resource: PromptResource;
  description: string;
  properties: {
    [name: string]: PromptOutputProperty;
  };
  propertiesOrder?: string[];
};

// the different of type maps out to different user input forms. upload is pdf/ppt

export const PromptInputTypesCore = {
  shortText: undefined,
  longText: undefined,
  dropdown: undefined,
  number: undefined,
  slidesUpload: 'pre',
  passage: 'pre',
} as const;

export type PromptInputType = keyof typeof PromptInputTypesCore;

export type PromptInput = {
  // customize
  id: string;
  type: PromptInputType;
  constraints?: PromptInputConstraints; //;
  order: number;
  value?: string;
  fileType?: string;
  label: string;
  description?: string;
  isRequired?: boolean;
  defaultValue?: string;
  allowEmpty?: boolean;
};
export type PromptInputData = Omit<
  PromptInput,
  'constraints' | 'order' | 'label' | 'description' | 'allowEmpty' | 'isRequired'
>;

export type PromptDropdownOption = {
  displayName: string;
  value: string;
  isEnterprise?: boolean; //Used for paywall
};

export type PromptInputConstraints = {
  maxLength?: number;
  minLength?: number;
  max?: number;
  min?: number;
  step?: number;
  options?: PromptDropdownOption[];
  // for upload
  allowedFileTypes?: string[];
  // for slider
};

export type PromptTestcase = {
  inputs: PromptInput[];
  id: string;
  creatorId: string;
  tenantId: string;
  modifiedAt: string;
  createdAt: string;
  isSuggestion: boolean;
  isSampleLesson: boolean;
};

export type PromptSingleTestCaseResultType = ResourceType | PromptInputType;

export type PromptSingleTestCaseResult = ById<{
  type: PromptSingleTestCaseResultType;
  id: string;
  value: string;
}>;

export type PromptResourceMapValue = {
  id: string;
  value: string;
  type: ResourceType;
  mediaThumbnailUrl?: string;
  mediaDownloadLocation?: string;
};
export type PromptResourceMap = ById<PromptResourceMapValue>;

export type PresentationContent = {
  currentSlideId?: string;
  slides: Slide[];
  attachments?: LessonAttachment[];
  deprecatedRounds: Round[];
  title?: string;
  description?: string;
  lastPublished?: number;
  coverImageId?: string;
  coverImageAltText?: string;
  livePresentationIds: string[];
  templateSharingPublic: boolean; //default false
  isBlockedFromSharing: boolean; //default false
  requireRealNames: boolean; // default true
  language?: string;
  clonedFromPresentationId?: string;
  clonedFromTenantId?: string;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  activeLivePresentations?: any; //TODO: fix this
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  metadata: any; //TODO: fix this
};
export type Presentation = {
  id: string;
  tenantId: string;
  createdAt: number;

  creatorId: string;
  parentId: string | null;
  isPrivate: boolean;
  isDeleted: boolean;
  deletedAt: number | null;
  content: PresentationContent;
  aiContent?: AiContent;
  modifiedAt?: number;
};

export type Collection = {
  collectionName: string;
  collectionId: string;
  collectedGeneratorIds: string[];
  tenantId: string;
  collectionCreatorId: string;
  parentId: string | null;
  createdAt: Date;
  modifiedAt: Date;
  isDeleted: boolean;
  deletedAt: Date | null;
};

export type SchoolInfo = {
  id: string;
  name: string;
  address: string;
};

export type LicenseInfo = {
  validFrom: number;
  validThrough: number;
  price: number;
};

export type PdmInfo = {
  name: string;
  email: string;
};

export type UsageInfo = {
  totalTeachers: number;
  totalStudents: number;
  totalLessonsCreated: number;
  totalLessonsPresented: number;
  totalReportInsightsCreated: number;
};

export type Quote = {
  quoteId: string;
  quoteFriendlyId: number;
  creatorId: string;
  supporterIds: string[];
  tenantId: string;
  schoolId: number;
  schoolAddress: string | null; //this is the format returned - schoolName, districtName, state`;
  createdAt: Date;
  extendedAt: Date;
  expiresAt: Date;
  licenseValidFrom: Date;
  licenseValidThrough: Date;
  licensePrice: number;
  pdmName: string;
  pdmEmail: string;
};

export type UserInsight = {
  userId: string;
  name: string;
  email: string;
  usage: UsageInfo;
};

export type QuoteAndUsage = {
  quote: Quote;
  usage: {
    ownUsage: UsageInfo | null;
    totalUsage: UsageInfo;
    userInsights: UserInsight[];
  } | null;
} | null;

export type Standards = {
  id: string;
  label: string;
  description: string;
};

export interface AugmentedStandards extends Standards {
  id: string;
  standardsSet: string;
  grades: string[];
  gradeLabel: string;
  subject: string;
  code: string;
  label: string;
  description: string;
}

export type GeneratorMinimumDTO = {
  id: string;
  title: string;
  coverImageId?: string | null;
  generatorPlan?: GeneratorCoarseLicenseLevel | null;
  learningStandards?: Standard[];
  description?: string;
};

export type PersonalPresentationContent = Omit<
  PresentationContent,
  'activeLivePresentations'
> & { activeLivePresentation?: ActiveGame };

export type PresentationDTO = {
  id: string;
  tenantId: string;
  createdAt: Date;
  modifiedAt: Date;
  creatorId: string;
  parentId: string | null;
  isPrivate: boolean;
  editCounter: number | null;
  isDeleted: boolean;
  deletedAt: Date | null;
  content: PersonalPresentationContent;
  aiContent?: AiContent;
};

export type GeneratedStudentResponse = {
  responseId: string;
  slideId: string;
  responsePrompt: string; //IDK, Very poor etc
  response: string;
};

export type DistrictUsageData = {
  createdPresentationCount: number;
  livePresentationCount: number;
  teacherCount: number;
  organizationId: string;
  organizationName: string;
};

export type SchoolUsageData = {
  createdPresentationCount: number;
  email: string;
  livePresentationCount: number;
  teacherId: string;
  teacherName: string;
};

export enum SupportedIntegrationLabel {
  GoogleDrive = 'google-drive',
}

export type SupportedIntegrationResponse = {
  authorizationUrl: string;
  accessToken?: { value: string; expiresAt: Date };
};

export type AccountType =
  | 'teacherPrimarySchool'
  | 'teacherMiddleSchool'
  | 'teacherHighSchool'
  | 'studentPrimarySchool'
  | 'studentMiddleSchool'
  | 'studentHighSchool'
  | 'higherEducation'
  | 'professional'
  | 'personal';

export type SchoolRoleType =
  | 'Teacher'
  | 'Instructional/Tech Coach'
  | 'Library Media Specialist'
  | 'School Administrator'
  | 'District Administrator'
  | 'Purchasing Manager'
  | 'Student'
  | 'Other';

export type SubjectType =
  | 'English Language Arts'
  | 'Math'
  | 'Science'
  | 'Social Studies'
  | 'Other';

export type GradeType =
  | 'Kindergarten'
  | '1st'
  | '2nd'
  | '3rd'
  | '4th'
  | '5th'
  | '6th'
  | '7th'
  | '8th'
  | '9th'
  | '10th'
  | '11th'
  | '12th'
  | '13th'
  | 'PD'
  | 'Higher Education'
  | 'Other';

export type IntendedUseType =
  | 'Create Excitement for Reading and Writing'
  | 'Give Immediate Feedback on Student Writing'
  | 'Prepare for Testing'
  | 'Create Interactive Presentations Using AI'
  | 'Other';

export type SignUpOriginType =
  | 'Social Media'
  | 'Professional Development Session'
  | 'Coworker Referral'
  | 'Conference Presentation'
  | 'Other';

export type DatabaseSchool = {
  schoolId: number;
  schoolName: string;
  city?: string;
  country: string;
  districtId?: number;
  districtName?: string;
  state?: string;
  zipCode?: string;
};
