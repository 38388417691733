import { useFlag, useUnleashContext } from '@unleash/proxy-client-react';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';

import { LogoSvg } from '../assets/icons';
import RocketSvg from '../assets/icons/RocketSvg';
import { IconWrapper } from './_atoms/IconWrapper';

const UnderMaintenance = ({ children }: { children?: React.ReactNode }) => {
  const session = useSession();
  const updateContext = useUnleashContext();

  useEffect(() => {
    // Unleash context
    if (
      session.status === 'authenticated' &&
      session.data?.user?.uid &&
      session.data?.user?.email
    ) {
      updateContext({
        userId: session.data.user.uid,
        properties: {
          email: session.data.user.email,
        },
      });
    }
  }, [session]);

  const isUnderMaintenance = useFlag('IS_UNDER_MAINTENANCE');
  if (!isUnderMaintenance) {
    return <>{children}</>;
  }

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 20px 20px 20px',
      }}
    >
      <IconWrapper color={'#F99746'} height={'200px'} width={'350px'}>
        <LogoSvg />
      </IconWrapper>

      <div>
        <h1
          style={{
            fontSize: '36px',
            textAlign: 'center',
          }}
        >
          🔔 Bell Rings Soon!
        </h1>
        <h3
          style={{
            fontSize: '20px',
            textAlign: 'center',
          }}
        >
          Our site is on a short recess for maintenance. <br /> When we’re back, expect an
          even better learning experience!
        </h3>
      </div>

      <div>
        <h3
          style={{
            fontSize: '16px',
            margin: '20px',
            textAlign: 'center',
          }}
        >
          We’re working hard to get everything back up and running as soon as possible.
          <br />
          Please check back in a few hours. We appreciate your patience!
        </h3>

        <IconWrapper color={'#F99746'} height={'50px'} width={'50px'}>
          <RocketSvg />
        </IconWrapper>
      </div>
    </div>
  );
};

export default UnderMaintenance;
