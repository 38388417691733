'use client';

import { useFlag } from '@unleash/proxy-client-react';
import { NextRouter, useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';

import useAccessBadge, {
  LicenseCoarseLevels,
  LicenseFineLevels,
  UserAccessBadge,
} from '../../hooks/useAccessBadge';

const isTargetUserProfile = (
  userAccessBadge: UserAccessBadge | undefined,
  router?: NextRouter,
) => {
  const isUSTeacherOnEnterprisePlan =
    userAccessBadge?.license.fineLevel === LicenseFineLevels.full &&
    userAccessBadge?.license.coarseLevel === LicenseCoarseLevels.enterprise;

  // const { utm_source: utmSource, utm_content: utmContent } = router?.query || {};
  // const isFacebookAdsIncomingUser =
  //   utmSource === 'facebook' && utmContent === 'quick_write';

  return isUSTeacherOnEnterprisePlan;
};

export function getRecordingsUrl(userAccessBadge: UserAccessBadge | undefined) {
  if (!userAccessBadge || !isTargetUserProfile(userAccessBadge)) return undefined;
  const baseUrl = 'https://insights.hotjar.com/sites/5062971/playbacks';
  const urlParams = `sort_by=-created&date=%7B%22DAYS_AGO%22:%7B%22created%22:15%7D%7D&filters=%7B%22EQUAL%22:%7B%22user_attributes.str.user_id%22:%22${userAccessBadge.user.id}%22%7D%7D`;
  return `${baseUrl}?${urlParams}`;
}

const HotjarSnippet = () => {
  const { accessBadge: userAccessBadge } = useAccessBadge();
  const router = useRouter();
  const isHotjarEnabled = useFlag('HOTJAR_MONITORING');

  const [offlineSessionId] = useState<string>(String(v4()));
  const [utmTracking, setUtmTracking] = useState<{
    source: string;
    content: string;
  }>({
    source: '',
    content: '',
  });

  useEffect(() => {
    function formattedStringOrNull(value: string | null | undefined) {
      if (!!value) {
        const escapedValue = String(value).replace(/"/g, '\\"');
        return `"${escapedValue}"`;
      } else {
        return null;
      }
    }

    function getFormattedScriptContent() {
      const scriptContent = `
        (function(h,o,t,j,a,r){
          try {
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:5062971,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            r.onload = function() {
              try {
                if (window.hj) {
                  window.hj('identify', ${formattedStringOrNull(
                    userAccessBadge?.user.id || offlineSessionId,
                  )}, {
                    schoolId: ${formattedStringOrNull(userAccessBadge?.organization?.id)},
                    schoolCity: ${formattedStringOrNull(
                      userAccessBadge?.organization?.city,
                    )},
                    schoolState: ${formattedStringOrNull(
                      userAccessBadge?.organization?.region,
                    )},
                    schoolDistrictId: ${formattedStringOrNull(
                      userAccessBadge?.organization?.parent?.id,
                    )},
                    subjects: ${formattedStringOrNull(
                      userAccessBadge?.user.profileInfo?.subjects?.join(','),
                    )},
                    coarseLicense: ${formattedStringOrNull(
                      userAccessBadge?.license.coarseLevel,
                    )},
                    fineLicense: ${formattedStringOrNull(
                      userAccessBadge?.license.fineLevel,
                    )},
                    schoolRole: ${formattedStringOrNull(
                      userAccessBadge?.user.profileInfo?.role,
                    )},
                    createdAt: ${userAccessBadge?.user.createdAt || null},
                    createdAtISO: ${formattedStringOrNull(
                      userAccessBadge?.user.createdAt
                        ? new Date(userAccessBadge?.user.createdAt).toISOString()
                        : null,
                    )},
                    utmSource: ${formattedStringOrNull(utmTracking.source)},
                    utmContent: ${formattedStringOrNull(utmTracking.content)},
                  });
                  console.log('Hotjar identify event set');
                }
              } catch (e) {
                console.error('Error during Hotjar identify');
              }
            };
            r.onerror = function(e) {
              console.error('Error running Hotjar script');
            };
            a.appendChild(r);
          } catch (e) {
            console.error('Error loading Hotjar script');
          }
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `;
      return scriptContent;
    }

    try {
      const currentUtmSource = router?.query?.utm_source;
      const currentUtmContent = router?.query?.utm_content;
      setUtmTracking({
        source: !!currentUtmSource ? String(currentUtmSource) : utmTracking.source,
        content: !!currentUtmContent ? String(currentUtmContent) : utmTracking.content,
      });

      const shouldTrackUser =
        !!userAccessBadge &&
        isHotjarEnabled &&
        isTargetUserProfile(userAccessBadge, router);

      let trackingScript = document.getElementById('hotjar-identify');
      if (!trackingScript && shouldTrackUser) {
        trackingScript = document.createElement('script');
        trackingScript.id = 'hotjar-identify';
        trackingScript.textContent = getFormattedScriptContent();
        document.head.appendChild(trackingScript);
      } else if (trackingScript && !shouldTrackUser) {
        trackingScript.remove();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [
    userAccessBadge,
    isHotjarEnabled,
    router,
    offlineSessionId,
    utmTracking.source,
    utmTracking.content,
  ]);

  return <div></div>;
};

export default HotjarSnippet;
