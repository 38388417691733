export const config = {
  cloudMessaging: {
    vapidKey:
      'BFXyM2M44MjtaQSH4B3s1jycvLnm9v3DcWgqwC1JoqDnvX0I0dW-8BvDZ4s4OuR1v31pduColECCdI99_jv-E1A',
  },
  backend: {
    externalApiUrl: 'https://api-test.curipod.com',
  },
  cdn: {
    url: 'https://images-cdn-staging.curipod.com',
  },
  sentry: {
    dsn: 'https://f7e5e7f1902940f09a4732ad668667d2@sentry.io/1757023',
  },
  stripe: {
    publicKey: 'pk_test_hFHXs9lIjMZOiaqXp6D2SwSX00AfqbYr2P',
  },
  appVersion: process.env.REACT_APP_VERSION,
  mixpanel: {
    token: 'b3147091d8b6e435d0dfeb43c9e3669c',
  },
  azure: {
    tenant: 'snapmentordev.onmicrosoft.com',
    tenantName: 'snapmentordev',
    clientId: 'cfb60a72-57fd-4cdc-b3f0-10eec6dcfff6',
    signInPolicy: 'B2C_1A_signin',
    joinPolicy: 'B2C_1A_join',
    signUpPolicy: 'B2C_1A_signup',
    enterpriseSignInPolicy: 'B2C_1A_enterprise_signin',
    enterpriseJoinPolicy: 'B2C_1A_enterprise_join',
    scopes: [
      'https://snapmentordev.onmicrosoft.com/cfb60a72-57fd-4cdc-b3f0-10eec6dcfff6/User.Read',
    ],
    enterpriseRedirectUri: 'https://id-dev.curipod.com',
    prRedirectUri: 'https://id-dev.curipod.com',
    redirectUri: ``,
  },
  onboarding: {
    version: 24,
  },
  whatsNew: {
    version: 24,
  },
  game: {
    url: 'dev.curi.live',
    apiUrl: 'https://dev-api-live.curipod.com',
  },
  legacyApp: {
    url: 'https://app-dev.curipod.com',
  },
  externalLinks: {
    howItWorks: 'https://test-landing.curipod.com/how-it-works',
  },
  unleash: {
    url: 'https://unleash-proxy-7epohblsoa-ey.a.run.app/proxy',
    clientKey: '7epohblsoa',
    appName: 'app-curipod-dev',
    refreshInterval: 60 * 5,
  },
  discovery: {
    generatorIds: [
      '53b38a50-c4ed-4bb9-9668-348b4f8620b6', // Phenomena - identify the lie - generator
      '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb', // fun debate practice
      'a439f49d-2ff6-4bb2-976e-4c6156a14d46', // choice board generator
      'ce27a986-9414-46d2-9ae3-102de53b6583', // history lesson
    ],
    fullLessonIds: [
      '09450f64-2788-4287-8af6-4346cee242b2', // full lesson ela example
      // '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb', // fun debate practice
      //'15c09734-a940-41e8-87ef-c233b3526dc7', // local lesson
    ],
    fullLessonIdsSocialStudies: [
      // '710c3709-6b3d-4420-9018-47b74a9090f1', // video game
      '5ea0accc-5cd0-4499-b161-e8664f7c3c26', // full lesson social stdies example
    ],
    fullLessonIdsMath: [
      // 'f665ec3d-047d-45fc-bb94-aaf7f5ab06ae', // Lawyers madness
      '506e563a-56aa-4c45-8d55-156d3797497f', // full lesson math example
    ],
    fullLessonIdsScience: [
      // '889c7d87-6f32-4c13-b947-089190b4964f', // animals in the wild
      '1afad20d-bdd7-43f0-953a-77e658f057ef', // full lesson science example
    ],
    didNotFindTryTheseSuggestionIds: [
      '53b38a50-c4ed-4bb9-9668-348b4f8620b6', // Phenomena - identify the lie - generator
      '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb', // fun debate practice
      'a439f49d-2ff6-4bb2-976e-4c6156a14d46', // choice board generator
    ],
    recommended: [
      '12cce8f2-7418-42bc-bd59-944f00dd3a12', // Best lesson ever
      '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb', // fun debate practice
      'a439f49d-2ff6-4bb2-976e-4c6156a14d46', // choice board generator
      '2ebb23e3-0fc2-4635-b2ff-84f07d6ba3ea', // idioms
    ],
  },
  testprep: {
    //!LOCAL
    // //STAAR RLA SCR
    // staarRLASCRArgumentative: '15c09734-a940-41e8-87ef-c233b3526dc7',
    // staarRLASCRInformational: '3c012b7b-9f4f-4d9c-8df9-59fa4fa60df1',
    // staarRLASCRFiction: '165cc4ef-53db-4429-9333-1e78d06ab2b2',
    // staarRLASCRRevisingSentences: '15c09734-a940-41e8-87ef-c233b3526dc7',
    // staarRLASCRUpload: '3654b20d-b09d-42b9-bbed-66a6c8af4ad6',
    // //STAAR RLA ECR
    // staarRLAECRInformationalComposition: '15c09734-a940-41e8-87ef-c233b3526dc7',
    // staarRLAECRArgumentativeComposition: '3c012b7b-9f4f-4d9c-8df9-59fa4fa60df1',
    // staarRLAECRInformationalCorrespondence: '29dbcf74-cffe-4732-93f9-8694e20ae119',
    // staarRLAECRArgumentativeCorrespondence: '15c09734-a940-41e8-87ef-c233b3526dc7',
    // staarRLAECRUploadInformational: '3654b20d-b09d-42b9-bbed-66a6c8af4ad6',
    // // staarRLAECRUploadArgumentative: '31ab970c-8137-40e0-a091-651ac215b3a4',
    // //STAAR Social Studies
    // staarSocialStudiesSCR: '29dbcf74-cffe-4732-93f9-8694e20ae119',
    // staarSocialStudiesSCRUpload: '3654b20d-b09d-42b9-bbed-66a6c8af4ad6',
    // //STAAR US History
    // staarUSHistorySCR: '15c09734-a940-41e8-87ef-c233b3526dc7',
    // staarUSHistorySCRUpload: '3654b20d-b09d-42b9-bbed-66a6c8af4ad6',
    // //STAAR Science
    // staarScienceSCR: '165cc4ef-53db-4429-9333-1e78d06ab2b2',
    // staarScienceSCRUpload: '3654b20d-b09d-42b9-bbed-66a6c8af4ad6',
    // //STAAR Biology
    // staarBiologySCR: '29dbcf74-cffe-4732-93f9-8694e20ae119',
    // staarBiologySCRUpload: '3654b20d-b09d-42b9-bbed-66a6c8af4ad6',
    // //STAAR Math
    // staarMathTextInput: '3c012b7b-9f4f-4d9c-8df9-59fa4fa60df1',
    // staarMathTextInputUpload: '3654b20d-b09d-42b9-bbed-66a6c8af4ad6',
    // //STAAR Algebra I
    // staarAlgebraITextInput: '15c09734-a940-41e8-87ef-c233b3526dc7',
    // staarAlgebraITextInputUpload: '3654b20d-b09d-42b9-bbed-66a6c8af4ad6',
    // //TELPAS
    // telpasTellAStoryAnswerQuestions: '15c09734-a940-41e8-87ef-c233b3526dc7',
    // telpasTELLAStoryFourPictures: '3c012b7b-9f4f-4d9c-8df9-59fa4fa60df1',
    // telpasTELLAStoryOnePicture: '29dbcf74-cffe-4732-93f9-8694e20ae119',
    // telpasDescribeThePictures: '165cc4ef-53db-4429-9333-1e78d06ab2b2',
    // telpasRespondToAScenario: '15c09734-a940-41e8-87ef-c233b3526dc7',
    // telpasWriteAboutBarGraph: '15c09734-a940-41e8-87ef-c233b3526dc7',
    // telpasWriteAboutPictureChart: '3c012b7b-9f4f-4d9c-8df9-59fa4fa60df1',
    // //CAASPP ELA Short Answer
    // caasppELAShortAnswerInformational: '15c09734-a940-41e8-87ef-c233b3526dc7',
    // caasppELAShortAnswerArgumentative: '3c012b7b-9f4f-4d9c-8df9-59fa4fa60df1',
    // caasppELAShortAnswerFiction: '165cc4ef-53db-4429-9333-1e78d06ab2b2',
    // caasppELAShortAnswerUpload: '3654b20d-b09d-42b9-bbed-66a6c8af4ad6',
    // //CAASPP ELA Performance Task
    // caasppELAPerformanceTaskInformationalComposition:
    //   '3c012b7b-9f4f-4d9c-8df9-59fa4fa60df1',
    // caasppELAPerformanceTaskArgumentativeComposition:
    //   '29dbcf74-cffe-4732-93f9-8694e20ae119',
    // caasppELAPerformanceTaskNarrativeComposition: '29dbcf74-cffe-4732-93f9-8694e20ae119',
    // caasppELAPerformanceTaskUploadInformational: '3654b20d-b09d-42b9-bbed-66a6c8af4ad6',
    // // caasppELAPerformanceTaskUploadArgumentative: 'de13a20d-680c-48c3-9435-fb017bd88be1',
    // // caasppELAPerformanceTaskUploadNarrative: 'c0271657-c55e-4c6d-a2fe-27c9e99fc4d2',
    //!STAGING
    //*STAAR RLA SCR
    staarRLASCRArgumentative: '3f78c546-fea9-49c7-b141-924269d171e1',
    staarRLASCRInformational: '12cce8f2-7418-42bc-bd59-944f00dd3a12',
    staarRLASCRFiction: '1f2d09fd-926f-4040-96bf-67241ec9ffaa',
    staarRLASCRRevisingSentences: '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb',
    staarRLASCRUpload: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    //*STAAR RLA ECR
    staarRLAECRInformationalComposition: '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb',
    staarRLAECRArgumentativeComposition: '1f2d09fd-926f-4040-96bf-67241ec9ffaa',
    staarRLAECRInformationalCorrespondence: 'ce27a986-9414-46d2-9ae3-102de53b6583',
    staarRLAECRArgumentativeCorrespondence: '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb',
    staarRLAECRUploadInformational: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    staarRLAECRUploadArgumentative: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    //*STAAR Social Studies
    staarSocialStudiesSCR: '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb',
    staarSocialStudiesSCRUpload: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    //*STAAR US History
    staarUSHistorySCR: '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb',
    staarUSHistorySCRUpload: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    //*STAAR Science
    staarScienceSCR: '1f2d09fd-926f-4040-96bf-67241ec9ffaa',
    staarScienceSCRUpload: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    //*STAAR Biology
    staarBiologySCR: 'ce27a986-9414-46d2-9ae3-102de53b6583',
    staarBiologySCRUpload: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    //*STAAR Math
    staarMathTextInput: '12cce8f2-7418-42bc-bd59-944f00dd3a12',
    staarMathTextInputUpload: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    //*STAAR Algebra I
    staarAlgebraITextInput: '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb',
    staarAlgebraITextInputUpload: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    //*TELPAS
    telpasTellAStoryAnswerQuestions: '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb',
    telpasTELLAStoryFourPictures: '12cce8f2-7418-42bc-bd59-944f00dd3a12',
    telpasTELLAStoryOnePicture: 'ce27a986-9414-46d2-9ae3-102de53b6583',
    telpasDescribeThePictures: '1f2d09fd-926f-4040-96bf-67241ec9ffaa',
    telpasRespondToAScenario: '0fac69ce-c68d-48e5-b6ce-c57f37fcc9ff',
    telpasWriteAboutBarGraph: '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb',
    telpasWriteAboutPictureChart: '12cce8f2-7418-42bc-bd59-944f00dd3a12',
    //*CAASPP ELA Short Answer
    caasppELAShortAnswerInformational: '0fac69ce-c68d-48e5-b6ce-c57f37fcc9ff',
    caasppELAShortAnswerArgumentative: '12cce8f2-7418-42bc-bd59-944f00dd3a12',
    caasppELAShortAnswerFiction: '1f2d09fd-926f-4040-96bf-67241ec9ffaa',
    caasppELAShortAnswerUpload: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    //*CAASPP ELA Performance Task
    caasppELAPerformanceTaskInformationalComposition:
      '12cce8f2-7418-42bc-bd59-944f00dd3a12',
    caasppELAPerformanceTaskArgumentativeComposition:
      'ce27a986-9414-46d2-9ae3-102de53b6583',
    caasppELAPerformanceTaskNarrativeComposition: 'ce27a986-9414-46d2-9ae3-102de53b6583',
    caasppELAPerformanceTaskUploadInformational: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    caasppELAPerformanceTaskUploadArgumentative: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    caasppELAPerformanceTaskUploadNarrative: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    //*CAASPP Math
    caasppMathTextInput: 'ce27a986-9414-46d2-9ae3-102de53b6583',
    caasppMathTextInputUpload: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    //*CAASPP Science
    caasppScienceSCR: '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb',
    caasppScienceSCRUpload: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    //*PSSA ELA Short Answer
    pssaSAInformational: '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb',
    pssaSAUpload: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    //*PSSA ELA Text Dependent Analysis
    pssaTDAInformational: '1f2d09fd-926f-4040-96bf-67241ec9ffaa',
    pssaTDANarrative: 'ce27a986-9414-46d2-9ae3-102de53b6583',
    pssaTDAUpload: '1f2d09fd-926f-4040-96bf-67241ec9ffaa',
    //*PSSA Science
    pssaScienceShortAnswer: '1f2d09fd-926f-4040-96bf-67241ec9ffaa',
    //*MICHIGAN M-STEP ELA
    mstepPBWInformationalComposition: '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb',
    mstepPBWUpload: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    //*NC STATE TEST ELA
    ncStateTestCRInformationalComposition: '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb',
    ncStateTestELAUpload: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    //*SBAC
    //SBAC ELA Short Answer
    sbacELAShortAnswerInformational: '0fac69ce-c68d-48e5-b6ce-c57f37fcc9ff',
    sbacELAShortAnswerArgumentative: '12cce8f2-7418-42bc-bd59-944f00dd3a12',
    sbacELAShortAnswerFiction: '1f2d09fd-926f-4040-96bf-67241ec9ffaa',
    sbacELAShortAnswerUpload: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    //SBAC ELA Performance Task
    sbacELAPerformanceTaskInformationalComposition:
      '12cce8f2-7418-42bc-bd59-944f00dd3a12',
    sbacELAPerformanceTaskArgumentativeComposition:
      'ce27a986-9414-46d2-9ae3-102de53b6583',
    sbacELAPerformanceTaskNarrativeComposition: 'ce27a986-9414-46d2-9ae3-102de53b6583',
    sbacELAPerformanceTaskUploadInformational: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    sbacELAPerformanceTaskUploadArgumentative: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    sbacELAPerformanceTaskUploadNarrative: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    //*ELPAC
    elpacWriteAboutAnExperience: '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb',
    //*NY REGENTS
    regentsTextAnalysisInformational: '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb',
    //*WIDA ACESS
    widaTierAWriteStory: 'ce27a986-9414-46d2-9ae3-102de53b6583',
    widaTierAMakeADecision: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
    widaTierBCStepByStepDescription: '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb',
    //*RACE
    raceConstructedResponse: '7e8a14e3-6fdf-4971-b654-8e444e0e1fbb',
    raceUpload: 'd3541b4a-60c6-485a-9ef4-7141ab8a232c',
  },
};

export type APPConfig = typeof config;
