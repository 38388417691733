import { useMutation, useQuery } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';

declare global {
  interface Window {
    structuredClone<T>(value: T): T;
  }
  function structuredClone<T>(value: T): T;
}

import backendService from '../services/backendService';
import useRouterMatch from './useRouterMatch';
export const accessBadgeCacheKey = 'accessBadge';

export enum LicenseCoarseLevels {
  enterprise = 'Enterprise',
  premium = 'Premium',
  freemium = 'Freemium',
}

export enum LicenseFineLevels {
  trial = 'Trial',
  full = 'Full',
}

export type SimplifiedUser = {
  id: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  email: string;
  profileInfo?: {
    role?: string;
    subjects?: string[];
    grades?: string[];
    intendedUses?: string[];
    otherIntendedUse?: string;
    signUpOrigin?: string;
    otherSignUpOrigin?: string;
  };
  createdAt: number;
};

export type SimplifiedLicense = {
  id?: string;
  source?:
    | 'EnterpriseOnboardingStarted'
    | 'PaidPilot'
    | 'FreePilot'
    | 'AmbassadorAccess'
    | 'OneOffExtension'
    | 'SignUp'
    | 'PremiumDiscontinued';
  coarseLevel: LicenseCoarseLevels;
  fineLevel: LicenseFineLevels;
  expiresAt?: number;
  createdAt: number;
};

export type SimplifiedOrganization = {
  id: string;
  type: 'School' | 'District' | 'Other';
  source: 'MCH' | 'Curipod' | 'User';
  name: string;
  primaryZipCode?: string;
  secondaryZipCode?: string;
  city?: string;
  region?: string;
  country?: string;
  ssoProvider?: 'Google' | 'Clever' | 'Classlink' | 'Feide';
  parent?: SimplifiedOrganization;
  createdAt: number;
};

// Kept for legacy purposes. Will be deprecated in the near future.
export type LegacyTenant = {
  tenantId: string;
  tenantName: string;
  organisationId?: string | null;
  isEnterprise?: boolean | null; // Deprecated - used by pods enterprise - dont migrate data before pod is 100% shutoff
  districtId?: string | null;
  districtName?: string | null;
  state?: string | null;
  country?: string | null;
  createdAt?: number | null;
  createdBy?: string | null;
  isPremium?: boolean | null; // Deprecated - used by pods - dont migrate data before pod is 100% shutoff
  isCuripodPremium?: boolean | null;
  isCuripodEnterprise?: boolean | null;
  curipodTrialExpirationDate?: number;
  curipodTrialReason?: string;
  curipodEnterpriseExpirationDate?: number;
};

export type RawUserAccessBadge = {
  role: 'Admin' | 'Member';
  user: SimplifiedUser;
  license?: SimplifiedLicense;
  organization?: SimplifiedOrganization;
  tenant: LegacyTenant;
};

export class UserAccessBadge {
  readonly role: 'Admin' | 'Member';
  readonly user: Readonly<SimplifiedUser>;
  readonly license: Readonly<SimplifiedLicense>;
  readonly organization?: Readonly<SimplifiedOrganization>;
  readonly tenant: Readonly<LegacyTenant>;

  constructor(
    role: 'Admin' | 'Member',
    user: SimplifiedUser,
    license: SimplifiedLicense | undefined,
    organization: SimplifiedOrganization | undefined,
    tenant: LegacyTenant,
  ) {
    this.role = role;
    this.user = Object.freeze(cloneDeep(user));
    this.license = license
      ? Object.freeze(cloneDeep(license))
      : {
          coarseLevel: LicenseCoarseLevels.freemium,
          fineLevel: LicenseFineLevels.full,
          createdAt: new Date().getTime(),
        };
    this.organization = Object.freeze(cloneDeep(organization));
    this.tenant = Object.freeze(cloneDeep(tenant));
    Object.freeze(this);
  }
}

function useAccessBadge() {
  const { tenantId: activeTenantId } = useRouterMatch();
  const { data, isFetching, isFetched } = useQuery<UserAccessBadge | undefined>(
    [accessBadgeCacheKey, activeTenantId],
    _ => {
      if (!activeTenantId) return undefined;
      return backendService.instance.users.getAccessBadge(activeTenantId);
    },
    { staleTime: 1000 * 60 * 60, enabled: !!activeTenantId },
  );

  const { mutate, isSuccess, isLoading } = useMutation(
    async ({
      schoolId,
      schoolCountry,
    }: {
      schoolId?: number;
      schoolCountry?: string;
      onSuccessFunction?: () => void;
    }) => {
      return backendService.instance.users.updateSchoolInfo({
        activeTenantId,
        schoolId,
        schoolCountry,
      });
    },
    {
      onSuccess: (_, { onSuccessFunction }) => {
        if (onSuccessFunction) onSuccessFunction();
      },
    },
  );
  return {
    accessBadge: data,
    isFetching,
    isFetched,
    updateSchoolInfo: mutate,
    isUpdatingSchoolInfo: isLoading,
    isSuccess: isSuccess,
  };
}

export default useAccessBadge;
